.footer {
    background: rgb(12, 12, 12);
    color: #fff;
    padding: 5px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    width: 30%;
  }
  
  .footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .social-icons li {
    margin-right: 10px;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 20px;
  }
  
  .copyright {
    text-align: center;
    margin-top: 0px;
  }