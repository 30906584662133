:root {
    --primary: rgba(132, 176, 103);
    font-family: "Press Start 2P", serif;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 5px solid var(--primary);
    border-radius: 0px;
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 16px 40px;
    border: 3px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  
  .btn--medium {
    padding: 8px 20px;
    font-size: 12px;
    font-family: "Press Start 2P", serif;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 28px;
    font-family: "Press Start 2P", serif;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }