.cards {
  padding: 1rem;
  background: rgb(12, 12, 12);
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 0px;
  transition: all 0.5s ease-in-out;
  box-shadow: none;
}

.cards__item:hover {
  box-shadow: 0 16px 38px rgba(132, 176, 103, 0.37);
}

.cards__item:hover .cards__item__img {
  filter: grayscale(0%);
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  -webkit-filter: drop-shadow(6px 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(6px 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 0px;
  overflow: hidden;
  text-decoration: none;
  background-color: black;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
  filter: grayscale(70%);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: white;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
      width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
      display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
      margin-bottom: 2rem;
  }
}
