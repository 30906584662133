/* Styles for smaller screens */

.home-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(12, 12, 12);
    align-items: center;
}

.profile-section {
  display: flex;
  padding: 20px;
  max-width: 70vw;
  width: 100%;
  gap: 20px;
}

.profile-photo {
  width: 30%;
  height: auto;
}

.profile-photo img{
  max-width: 100%;
  max-height: 100%;
}

.profile-text {
  flex: 1;
  margin-left: 10px;
}

.profile-text h1 {
  margin: 0;
  font-size: 2.5rem;
  color: rgb(179, 179, 179);
}

.profile-text .englishname {
  color: rgba(132, 176, 103);
  /* font-family: "Shadows Into Light", serif; */
}

.profile-text .chinesename {
  color: rgba(132, 176, 103);
  font-family: "Zhi Mang Xing", serif;
  font-weight: 300;
  
}

.profile-text p {
  margin: 30px 0 0;
  font-size: 1.2rem;
  color: rgb(179, 179, 179);
  line-height: 1.5;
  font-weight: 300;
}

.profile-text .tagline {
  font-size: 2rem;
  font-family: "Shadows Into Light", serif;
}

.profile-text a {
  color: rgba(132, 176, 103);
}

.social-links {
  margin: 30px 0 0;
}

.social-links a {
  margin: 0 8px 0;
  color: rgb(179, 179, 179);
  font-size: 3rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: rgba(132, 176, 103);
}


  .text-section {
    width: 100%;
    padding: 0rem;
    margin-bottom: 50px;
  }

  .text-section a {
    color: rgba(132, 176, 103);
  }

  .text-section b {
    color: rgba(132, 176, 103);
  }

  .text-line {
    margin: 0 20% 0;
    width: 60%;
  }

  .text-line h1{
    font-size: 2.5rem;
    color: rgba(132, 176, 103);
    border-left: 2px solid rgb(179, 179, 179);
    padding-left: 10px;
  }

  .text-line ul{
    list-style-position: inside;
    list-style-type: "- ";
  }

  .milestone ul{
    list-style-position: inside;
    list-style-type: "🌱 ";
  }

  .text-line li{
    margin: 20px 0 0;
    font-size: 1.2rem;
    color: rgb(179, 179, 179);
    line-height: 1.5;
  }

  /* waving animation */
  .wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 4s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
  }

  /* picture section */
  .hero-container {
    background-image: url('./images/img-home.jpg');
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color: rgba(132, 176, 103);
    font-size: 100px;
    margin-left: 1vw;
    margin-top: 50px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.80);
    font-size: 32px;
  }
  
  .hero-btns {
    margin-left: 1vw;
    margin-top: 50px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .profile-section {
      padding: 0px;
      gap: 0px;
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: 0 10% 0;
    }

    .profile-photo {
      width: 60%;
      height: auto;
    }

    .profile-text {
      flex: 1;
      margin-left: 0px;
    }

    .profile-text h1 {
      margin: 0;
      font-size: 1.8rem;
      color: rgb(179, 179, 179);
    }
    
    .profile-text p {
      margin: 15px 0 0;
      color: rgb(179, 179, 179);
    }

    .social-links a {
      margin: 0px 8px 0px;
      color: rgba(132, 176, 103);
      font-size: 2rem;
      transition: color 0.3s ease;
    }

    .text-line {
      margin: 0 10% 0;
      width: 80%;
    }

    .text-line h1{
      font-size: 1.8rem;
      color: rgba(132, 176, 103);
    }


    .hero-container > h1 {
      font-size: 50px;
      margin-top: 20px;
    }
    .hero-btns {
      max-width: 60%;
      margin-left: 0vw;
      margin-top: 50px;
    }

    .hero-btns .btn {
      margin: 0px;
    }
  }