.doc-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(12, 12, 12);
    align-items: center;
    min-width: 100%;
    min-height: 90vh;
}

.doc-container iframe{
    justify-self: center;
    border: none;
    background-color: rgb(12, 12, 12);
    width: 80%;
    aspect-ratio: 1 / 1.41;
}